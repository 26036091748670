import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { ModalFormWrapper } from '@/components/form/modal';
import { MenusRead } from '@/data/management/menu.graphql';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { useModal } from '@/providers/modal';
import { Menu, QueryMenusReadArgs } from '@/types/schema';
import { Divider, MenuItem, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import VendorForm from '../dashboard/management/vendors/form';

export default function VendorSelect( props: Omit<FormGraphQLAutoCompleteProps<Menu, QueryMenusReadArgs>, 'query' | 'queryKey'> ) {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const creatable = usePermissions( permissions.menus.write );
	const [ , { value = [] }, { setValue } ] = useField( props.name );
	
	return (
		<FormGraphQLAutocomplete<Menu>
			label={t( 'common:vendor' )}
			queryKey={[ 'vendor' ]}
			query={MenusRead}
			getOptionLabel={( {
				name,
				vendorName,
				vendorEmail,
				vendorContact,
			}: Menu ) => `${vendorName || name} ${vendorEmail || ''} ${vendorContact || ''}`}
			renderOption={( props, option ) => (
				<Fragment>
					<MenuItem {...props} key={option.id ?? ''}>
						<Stack spacing={.3}>
							<Typography fontWeight={500}>
								{option.vendorName || option.name}
							</Typography>
							{option.vendorEmail && (
								<Typography color='text.secondary'>
									{option.vendorEmail}
								</Typography>
							)}
							{option.vendorContact && (
								<Typography color='text.secondary'>
									{option.vendorContact}
								</Typography>
							)}
							{option.vendorPhone && (
								<Typography color='text.secondary'>
									{option.vendorPhone}
								</Typography>
							)}
						</Stack>
					</MenuItem>
					<Divider sx={{ my: 1 }}/>
				</Fragment>
			)}
			onAdd={creatable ? () => showModal( VendorForm, { maxWidth: 'sm' }, {
				Wrapper : ModalFormWrapper,
				onSubmit: ( vendor ) => setValue( props.multiple ? [ ...value, vendor ] : vendor ),
			} ) : undefined}
			{...props}
			textFieldProps={{ label: props.label || t( 'common:vendor' ), ...props.textFieldProps }}
		/>
	);
}
