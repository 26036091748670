import { LineItem } from '@/types/schema';
import { partition } from 'lodash';
import { flatten, reduce } from 'lodash-es';

export function lineItemTotalCalculation( lineItem: LineItem, cashDiscount?: number, isPurchase?: boolean ) {
	const modifiersTotal = isPurchase
		? 0
		: reduce( flatten( lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers
			?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] ) ) ), ( total,
			modifier ) => {
			total += modifier?.value * lineItem.metadata?.[ modifier.externalId ] * lineItem.quantity || 0;
			return total;
		}, 0 );
	
	let total = ( isPurchase
		? lineItem.cost
		: lineItem.price ) * lineItem.quantity + modifiersTotal;
	const taxes = isPurchase
		? []
		: lineItem.prices?.filter( ( price ) => price.metadata?.externalTax && price.metadata?.useTax );
	const prices = isPurchase ? [] : lineItem.prices?.filter( ( price ) => !price.metadata?.externalTax );
	
	const [ discounts, fees ] = partition( prices, ( { value } ) => value < 0 );
	
	total += discounts.reduce( ( sum, price ) => sum + ( price.isPercent
		? price.value * total / 100
		: price.value * ( price.quantity || 1 ) * lineItem.quantity ), 0 );
	total += ( lineItem.tax || 0 ) * total / 100;
	total += fees.reduce( ( sum, price ) => sum + ( price.isPercent
		? price.value * total / 100
		: price.value * ( price.quantity || 1 ) ), 0 );
	
	total += taxes?.reduce( ( sum, price ) => sum + ( price.isPercent
		? price.value * total / 100
		: price.value * lineItem.quantity ), 0 ) || 0;
	
	const noItemWithCashDiscount = !lineItem.id && !lineItem.item && lineItem.price > 0 && cashDiscount > 0;
	
	return { total, noItemWithCashDiscount };
}
