import { CompanyTagsType } from '@/modals/companyTags';
import { companyOrderTagsColors } from '@/pages/dashboard/commerce/chips/deliveryStatusChip';
import { isEmpty, keyBy, mapValues } from 'lodash-es';

export function lineItemsStatusColors( lineItemTags: CompanyTagsType[], darkMode: boolean ): Record<string, string> {
	if ( isEmpty( lineItemTags ) ) return {};
	const lineItemTagsColors = companyOrderTagsColors( darkMode );
	return mapValues( keyBy( lineItemTags, 'name' ), ( tag ) => lineItemTagsColors[ tag.color ].bgcolor );
}

export function deliveryStatusColors( orderTags: CompanyTagsType[], darkMode: boolean ): Record<string, string> {
	if ( isEmpty( orderTags ) ) return {};
	const orderTagsColors = companyOrderTagsColors( darkMode );
	return mapValues( keyBy( orderTags, 'name' ), ( tag ) => orderTagsColors[ tag.color ].bgcolor );
}

export const getRowProps = ( row: any, darkMode: boolean ) => {
	if ( !row?.deliveryStatus ) return undefined;
	const orderTags: CompanyTagsType[] = row?.company?.tags?.orderTags?.filter( ( tag ) => tag?.forOrder );
	const purchaseTags: CompanyTagsType[] = row?.company?.tags?.orderTags?.filter( ( tag ) => tag?.forPurchase );
	const tags = 'type' in row ? orderTags : purchaseTags;
	
	const colorMap = deliveryStatusColors( tags, darkMode );
	
	if ( colorMap?.[ row.deliveryStatus ] ) {
		return {
			sx: {
				backgroundImage: `linear-gradient(to right, ${colorMap[ row.deliveryStatus ]}, transparent, transparent, transparent)`,
			},
		};
	}
	
	return undefined;
};
