import Attachment from '@/components/fileUploading/attachment';
import { lineItemsStatusColors } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import PageSection from '@/components/page/section';
import { axiosClient } from '@/data';
import { queryGraphQL } from '@/data/apollo';
import currencyFormat from '@/helpers/currencyFormat';
import { linkFormat } from '@/helpers/linkFormat';
import { CompanyTagsType } from '@/modals/companyTags';
import LineItemsToRefund from '@/modals/lineItemRefund';
import PurchaseFromInvoice from '@/modals/purchaseFromInvoice';
import { calculations, getSubTotalAfterOrderDiscount } from '@/pages/dashboard/commerce/components/calculations';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { LineItemsFetch } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import CommerceItemsUpdating from '@/pages/dashboard/commerce/invoices/commerceItemsUpdating';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order, QueryLineItemsReadArgs, SimpleLineItem } from '@/types/schema';
import type { LineItemLayoutType } from '@/types/templates';
import {
	Add as AddIcon,
	Archive as ArchiveIcon,
	CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
} from '@mui/icons-material';
import {
	Box,
	Chip,
	Divider,
	PaletteMode,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Theme,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { partition } from 'lodash';
import { isEmpty, map, sortBy, sum, toLower, uniqBy } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { Pricing } from '../pricing';
import { invoiceLineItemsSelection } from './clientView/invoiceSelection';

export const CARD_FEE_LABEL = 'Card Processing Fee';

export default function LineItemsClient( {
	invoice,
	pricing,
	removeTableBorder,
	layoutColor,
	lineItemLayout = 'default',
	invoicesBalance,
	pickup,
	selectionsMenu,
	permissions,
	isPdf,
	isView,
	themeMode,
}: {
	invoice: Order,
	pricing: Pricing,
	selectionsMenu?: string[],
	removeTableBorder?: boolean,
	layoutColor?: string,
	lineItemLayout?: LineItemLayoutType,
	invoicesBalance?: number,
	permissions?: string[],
	pickup?: boolean,
	isPdf?: boolean,
	isView?: boolean,
	themeMode?: PaletteMode
} ) {
	const { showModal } = useModal();
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const mediaSize = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isOwner = permissions?.includes( 'OWNER' );
	const isAdmin = permissions?.includes( 'ADMIN' );
	const darkMode = themeMode === 'dark';
	const lineItemTags: CompanyTagsType[] = invoice.company?.tags?.lineItemTags?.filter( ( tag ) => tag?.forOrder ) || [];
	const colorsMap = lineItemsStatusColors( lineItemTags, darkMode );
	
	lineItemLayout = invoice.type === 'STATEMENT' ? 'industrial' : lineItemLayout;
	const expandOrMobile = lineItemLayout === 'expand' || mediaSize;
	const industrialLayout = lineItemLayout === 'industrial';
	const uniqueCategoriesLineItems: SimpleLineItem[] = uniqBy( invoice.lineItems, 'category.name' );
	
	const statementView = invoice.type === 'STATEMENT';
	const accountView = invoice.type === 'ACCOUNT';
	// show/hide sections
	const selections = selectionsMenu ? [ ...selectionsMenu ] : invoice.company.metadata?.selectionsMenu || [];
	
	const sortedDiscountsAndFees = [ ...sortBy( !isEmpty( pricing.prices )
		? [ ...pricing.prices ]
		: [], [ ( o ) => o.value ] ) ];
	const [ discounts, fees ] = partition( sortedDiscountsAndFees
		.filter( ( price ) => !price.metadata?.cloverTaxPercent ), ( { value } ) => value < 0 );
	
	const subTotalAfterDiscount = getSubTotalAfterOrderDiscount( invoice, discounts );
	const companyCardFeeValue = invoice.company?.metadata?.cardFee;
	
	const {
		      showUnit,
		      showName,
		      showTotal,
		      showPrice,
		      showQuantity,
		      showCategory,
		      showDescription,
		      showCode,
		      showSku,
		      expandedLines,
		      showTaxedLineItems,
		      hideImage,
	      } = invoiceLineItemsSelection( selections );
	
	const {
		      subTotal,
		      grandTotal,
		      taxTotal,
		      nonTaxPercentLineItemsTaxesData,
		      orderTaxCal,
		      multiTaxesData,
		      orderDiscounts,
	      } = calculations( invoice, pricing );
	
	const hasOnePaidPayment = invoice.payments?.filter( ( payment ) => payment.status === 'PAID' )?.length === 1;
	const isPaid = invoice.status === 'PAID';
	const refundedLineItems = invoice.lineItems?.filter( ( lineItem ) => lineItem.metadata?.refunded );
	const allLineItemsRefunded = refundedLineItems?.length === invoice.lineItems?.length;
	
	let cloverLineItems = invoice.lineItems?.filter( ( lineItem ) => lineItem.externalId );
	const serviceCompany = toLower( invoice.company?.industry || '' ) === 'services' && 'Service';
	
	return (
		<PageSection
			primary={`${invoice.lineItems?.length} x ${isPdf ? 'Items' : t( 'common:items' )}`}
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}
			actions={[
				invoice.lineItems?.length > 0 && [ 'ORDER', 'INVOICE' ].includes( invoice.type ) && isView && {
					name   : 'Create Purchase',
					icon   : <AddIcon/>,
					onClick: () => {
						showModal( PurchaseFromInvoice, { maxWidth: 'xl' }, {
							invoice,
							lineItems: invoice.lineItems,
							selectionsMenu,
						} );
					},
				},
				invoice.lineItems?.length > 0 && [ 'ORDER', 'INVOICE' ].includes( invoice.type ) && isView && {
					name   : invoice.metadata?.stock ? t( 'common:put-back' ) : t( 'commerce:update-stock' ),
					details: t( 'commerce:confirm-stock' ),
					icon   : <ArchiveIcon/>,
					onClick: async () => {
						const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
							query    : LineItemsFetch,
							variables: { options: { filter: { order: invoice.id } } },
						} );
						if ( isEmpty( lineItemsRead?.items ) ) {
							enqueueSnackbar( t( 'commerce:empty-stock' ), { variant: 'default' } );
							return;
						} else {
							return showModal( CommerceItemsUpdating, {
								maxWidth: 'lg',
								onClose : ( event, reason ) => stayOpen( event, reason ),
							}, { commerce: invoice } );
						}
					},
				},
				!isEmpty( cloverLineItems ) && !allLineItemsRefunded && isView && ( isAdmin || isOwner ) && ( hasOnePaidPayment && ( isPaid || refundedLineItems?.[ 0 ] ) ) && invoice.externalId && {
					name   : 'Refund line Items',
					icon   : <CurrencyExchangeRoundedIcon/>,
					onClick: async () => {
						const data: any = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
							id     : invoice.id,
							staffId: staff?.id,
						} ).catch( () => [] );
						
						if ( data?.data?.commerce ) {
							cloverLineItems = data.data.commerce.lineItems?.filter( ( lineItem ) => lineItem.externalId );
							
							showModal( LineItemsToRefund, { maxWidth: 'md' }, {
								invoice  : data.data.commerce,
								lineItems: cloverLineItems,
								selectionsMenu,
							} );
						}
					},
				} ].filter( Boolean )}>
			<TableContainer
				sx={{
					'borderRadius'       : !removeTableBorder && 2,
					'border'             : !removeTableBorder && 1,
					'borderColor'        : 'divider',
					'.MuiTableCell-root' : { fontSize: { xs: '12px', sm: '1.2333rem' } },
					'.MuiTypography-root': { fontSize: { xs: '12px', sm: '1.2333rem' } },
					'.MuiTableCell-root ': {
						border  : 0,
						width   : 'fit-content',
						fontSize: { sm: !expandedLines ? '1.2333rem' : 'auto', xs: '12px' },
					},
				}}>
				<Table size='small' cellSpacing={2}>
					{!expandOrMobile ? (
						<TableHead
							sx={{
								'bgcolor'            : layoutColor || 'divider',
								'display'            : 'table-row-group',
								'.MuiTableCell-root ': { border: 0, bgcolor: layoutColor },
							}}>
							<TableRow>
								<TableCell
									sx={{
										width: !industrialLayout
											? '60% !important'
											: '30%',
									}}>
									{serviceCompany || ( isPdf ? 'Product' : t( 'common:product' ) )}
								</TableCell>
								{( industrialLayout || accountView ) && (
									<TableCell
										colSpan={statementView || accountView ? 3 : 1}>
										{showDescription ? isPdf ? 'Description' : t( 'common:description' ) : ''}
									</TableCell>
								)}
								{!statementView && !accountView && (
									<TableCell width='10%'>
										{showUnit ? isPdf ? 'Unit' : t( 'common:unit' ) : ''}
									</TableCell>
								)}
								{!pickup && !statementView && (
									<TableCell width='8%'>
										{showPrice ? isPdf ? 'Price' : t( 'common:price' ) : ''}
									</TableCell>
								)}
								{!statementView && !accountView && (
									<TableCell width='8%' sx={{ textAlign: 'right' }}>
										{showQuantity ? isPdf ? 'Quantity' : t( 'common:quantity' ) : ''}
									</TableCell>
								)}
								{!pickup && (
									<TableCell align='right' colSpan={2}>
										{!statementView && !accountView ? isPdf ? 'Total' : t( 'common:total' ) : isPdf
											? 'Balance'
											: t( 'common:balance' )}
									</TableCell>
								)}
							</TableRow>
						</TableHead>
					) : (
						<TableHead
							sx={{
								'bgcolor'            : layoutColor || 'divider',
								'display'            : 'table-row-group',
								'.MuiTableCell-root ': { border: 0, bgcolor: layoutColor },
							}}>
							<TableRow>
								<TableCell width='80%'>{serviceCompany || ( isPdf
									? 'Product'
									: t( 'common:product' ) )}
								</TableCell>
								{!pickup
									&& <TableCell width='20%' align='right'>{isPdf ? 'Total' : t( 'common:total' )}</TableCell>}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{!Boolean( invoice.lineItems.length ) ? (
							<TableRow>
								<TableCell colSpan={5}>
									<Typography
										textAlign='center'
										p={1}
										color='text.secondary'>
										{isPdf ? 'No items' : t( 'common:no-items' )}
									</Typography>
								</TableCell>
							</TableRow>
						) : map( uniqueCategoriesLineItems, ( { category } ) => (
							<Fragment key={category?.name || 'other'}>
								{showCategory && category?.name && (
									<TableRow>
										<TableCell sx={{ pb: 0, pt: 1, pl: 1 }}>
											<Tooltip title='Category' placement='right'>
												<Box sx={{ width: 'fit-content' }}>
													<Typography
														sx={{
															color     : 'text.secondary',
															fontSize  : '12px !important',
															fontWeight: '500',
														}}>
														{category?.name}
													</Typography>
												</Box>
											</Tooltip>
										</TableCell>
									</TableRow>
								)}
								{invoice.lineItems.filter( ( lineItem ) => lineItem.category?.name === category?.name )
									.map( ( lineItem, index ) => {
										const descriptionHasPound = lineItem.description?.includes( '#' );
										const descriptionHasStar = lineItem.description?.includes( '*http' );
										const lastPoundIndex = descriptionHasPound ? lineItem.description.lastIndexOf( '#' )
											: lineItem?.name?.lastIndexOf( '#' );
										const linkPart = descriptionHasStar && lineItem.description.split( '*http' )[ 1 ];
										let cloverOrderNumber;
										let linkInOrderName = false;
										if ( lastPoundIndex > -1 ) {
											if ( descriptionHasPound ) {
												const orderNumber = lineItem.description?.slice( lastPoundIndex + 1 )
													.split( ' ' )[ 0 ];
												if ( orderNumber.length > 12 ) {
													cloverOrderNumber = orderNumber;
												} else {
													const orderNumber = lineItem?.name?.slice( lastPoundIndex + 1 )
														.split( ' ' )[ 0 ];
													if ( orderNumber.length > 12 ) {
														linkInOrderName = true;
														cloverOrderNumber = orderNumber;
													}
												}
											} else {
												const orderNumber = lineItem?.name?.slice( lastPoundIndex + 1 ).split( ' ' )[ 0 ];
												if ( orderNumber.length > 12 ) {
													linkInOrderName = true;
													cloverOrderNumber = orderNumber;
												}
											}
										}
										let orderLink;
										if ( linkPart ) {
											orderLink = `http${linkPart}`;
										}
										
										const hasExternalTax = lineItem.prices?.find( ( price ) => price.metadata?.useTax && price.value > 0 );
										const hasTax = lineItem.tax > 0 || hasExternalTax || invoice.taxTotal > 0 && lineItem.orderTax;
										
										const OrderNumberLinkComponent = linkFormat( lineItem?.name, cloverOrderNumber, `https://${process.env.NEXT_PUBLIC_DEV
											? 'sandbox.dev'
											: 'www'}.clover.com/r/${cloverOrderNumber}` );
										
										const linkedPurchaseNumber = lineItem.linkedPurchase
											? `(#${lineItem.linkedPurchase?.metadata?.customNumber || lineItem.linkedPurchase.number})`
											: '';
										
										return (
											<Fragment key={index}>
												{!expandOrMobile ? (
													<TableRow>
														<TableCell sx={{ py: 0 }}>
															<Stack direction='row' spacing={1}>
																{showName ? cloverOrderNumber && linkInOrderName
																	? (
																		<Stack direction='row' spacing={1}>
																			{OrderNumberLinkComponent}
																			<Typography>{linkedPurchaseNumber}</Typography>
																		</Stack>
																	)
																	: (
																		<Stack direction='row' spacing={1} alignItems='center'>
																			<Typography
																				sx={{ fontSize: '1.2993rem' }}
																				fontWeight='normal'>
																				{`${lineItem.name || ''} ${linkedPurchaseNumber}`}
																			</Typography>
																			{showTaxedLineItems && hasTax && (
																				<Tooltip
																					title='This item is taxable'
																					placement='right'>
																					<Chip
																						variant='alpha'
																						color='default'
																						label='Tax'
																						sx={{
																							fontStyle: 'italic',
																							fontSize : '11px !important',
																						}}
																					/>
																				</Tooltip>
																			)}
																		</Stack>
																	) : null}
																{lineItem.metadata?.refunded && (
																	<Chip
																		variant='alpha'
																		color='warning'
																		label={isPdf ? 'Refunded' : t( 'common:refunded' )}
																	/>
																)}
																{lineItem?.status && !isPdf && (
																	<Tooltip title={`This item is tagged as ${lineItem.status}`}>
																		<Typography
																			sx={{
																				color: colorsMap?.[ lineItem.status ]
																					? `${colorsMap[ lineItem.status ]} !important`
																					: '#3f50b5 !important',
																			}}>
																			{lineItem.status}
																		</Typography>
																	</Tooltip>
																)}
															</Stack>
														</TableCell>
														{( industrialLayout || accountView ) && (
															<TableCell
																colSpan={statementView || accountView ? 3 : 1}
																sx={{
																	py: !expandedLines ? 0 : 'auto',
																}}>
																{showDescription ? lineItem.description || '' : ''}
															</TableCell>
														)}
														{!statementView && !accountView && (
															<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																{showUnit ? lineItem.unit : ''}
															</TableCell>
														)}
														{!pickup && !statementView && (
															<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
																{showPrice ? currencyFormat( lineItem.price ) : ''}
															</TableCell>
														)}
														{!statementView && !accountView && (
															<TableCell sx={{ py: !expandedLines ? 0 : 'auto', textAlign: 'right' }}>
																{showQuantity ? lineItem.quantity : ''}
															</TableCell>
														)}
														{!pickup && showTotal && (
															<TableCell
																align='right'
																colSpan={2}
																sx={{ py: !expandedLines ? 0 : 'auto' }}>
																{currencyFormat( lineItem.price * lineItem.quantity )}
															</TableCell>
														)}
													</TableRow>
												) : (
													<TableRow>
														<TableCell sx={{ py: !expandedLines ? 0 : 'auto' }}>
															{showName ? cloverOrderNumber && linkInOrderName
																? (
																	<Stack direction='row' spacing={1}>
																		{OrderNumberLinkComponent}
																		<Typography>{linkedPurchaseNumber}</Typography>
																	</Stack>
)
																: (
																	<Typography>
																		{lineItem?.name || ''} {showTaxedLineItems && hasTax
																		? '(T)'
																		: ''} {linkedPurchaseNumber}
																	</Typography>
																) : null}
															{!statementView && (
																<Stack direction='row' spacing={1}>
																	{showUnit && (
																		<Typography color='text.secondary' mr={2}>
																			{lineItem.unit}
																		</Typography>
																	)}
																	{showQuantity && (
																		<Typography color='text.secondary' mr={2}>
																			{lineItem.quantity}
																		</Typography>
																	)}
																	{!pickup && showPrice && (
																		<Typography color='text.secondary'>
																			{currencyFormat( lineItem.price )}
																		</Typography>
																	)}
																</Stack>
															)}
														</TableCell>
														{!pickup && showTotal && (
															<TableCell
																align='right'
																sx={{ verticalAlign: 'top', py: !expandedLines ? 0 : 'auto' }}>
																{currencyFormat( lineItem.price * lineItem.quantity )}
															</TableCell>
														)}
													</TableRow>
												)}
												{/* Modifier Groups*/}
												{lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
													?.map( ( modifier, index ) => (
														<Fragment key={index}>
															<TableRow
																sx={{
																	'.MuiTableCell-root': {
																		color     : 'text.secondary',
																		fontSize  : 12,
																		py        : 0,
																		lineHeight: !expandedLines ? 1 : 'auto',
																	},
																}}>
																<TableCell colSpan={!expandOrMobile ? 5 : 1}>
																	{`${modifier?.name || ''} ${!pickup ? modifier.isPercent
																		? `(${modifier.value}%)`
																		: ` - ${currencyFormat( modifier.value )} ${lineItem.metadata?.[ modifier.externalId ]
																			? `x ${lineItem.metadata?.[ modifier.externalId ] * lineItem.quantity}`
																			: `x ${lineItem.quantity}`}` : ''}`}
																</TableCell>
																{!pickup && (
																	<TableCell
																		align='right'>
																		{modifier.isPercent
																			? currencyFormat( modifier.value * ( lineItem.price * lineItem.quantity / 100 ) )
																			: currencyFormat( modifier.value * ( lineItem.metadata?.[ modifier.externalId ] || 1 ) * lineItem.quantity )}
																	</TableCell>
																)}
															</TableRow>
														</Fragment>
													) ) )}
												{/* LineItem Prices */}
												{lineItem.prices?.filter( ( price ) => !price.metadata?.externalTax )
													?.map( ( fee, index ) => {
														const modifierTotal = lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
															?.reduce( ( total,
																modifier ) => total + modifier.value * lineItem.metadata?.[ modifier.externalId ], 0 ) );
														return (
															<Fragment key={index}>
																<TableRow
																	sx={{
																		'.MuiTableCell-root': {
																			color: 'text.secondary',
																			py   : .2,
																		},
																	}}>
																	<TableCell colSpan={!expandOrMobile ? 5 : 1}>
																		{`${fee.name} ${!pickup
																			? fee.isPercent
																				? `(${fee.value}%)`
																				: ` (${currencyFormat( fee.value )}) ${fee.quantity > 1
																					? `x ${fee.isPercent
																						? fee.quantity
																						: fee.quantity * lineItem.quantity}`
																					: `x ${fee.isPercent ? '1' : lineItem.quantity}`}`
																			: ''}`}
																	</TableCell>
																	{!pickup && (
																		<TableCell align='right'>
																			{fee.isPercent
																				? currencyFormat( fee.value / 100 * ( lineItem.price * lineItem.quantity + sum( modifierTotal ) ) )
																				: currencyFormat( fee.value * ( fee.quantity || 1 ) * lineItem.quantity )}
																		</TableCell>
																	)}
																</TableRow>
															</Fragment>
														);
													} )}
												
												{/*Line Item Image and Description*/}
												{!lineItem.image && !showCode && !showSku && !showDescription ? undefined : (
													<Fragment>
														{Boolean( lineItem.image || lineItem.description || lineItem.code || lineItem.sku ) && !statementView && !accountView && (
															<TableRow>
																<TableCell colSpan={accountView ? 5 : 6} sx={{ pt: 0 }}>
																	<Stack direction='row'>
																		{!hideImage && Boolean( lineItem.image ) && (
																			<Attachment
																				removeDownload
																				src={lineItem.image}
																				imageSX={{
																					width    : 60,
																					height   : 60,
																					objectFit: 'cover',
																					mb       : 0,
																					mr       : 1,
																				}}
																			/>
																		)}
																		{Boolean( lineItem.description || lineItem.code || lineItem.sku ) && (
																			<Box
																				sx={{
																					whiteSpace: 'pre-line',
																					color     : 'text.secondary',
																					lineHeight: 1.2,
																				}}>
																				<Stack spacing={1} direction='row'>
																					{showCode && lineItem.code && (
																						<Typography color='text.secondary'>
																							#{lineItem.code}
																						</Typography>
																					)}
																					{showSku && lineItem.sku && (
																						<Typography color='text.secondary'>
																							{lineItem.sku}
																						</Typography>
																					)}
																				</Stack>
																				{showDescription && cloverOrderNumber && !linkInOrderName
																					? linkFormat( lineItem.description, cloverOrderNumber, `https://${process.env.NEXT_PUBLIC_DEV
																						? 'sandbox.dev'
																						: 'www'}.clover.com/r/${cloverOrderNumber}` )
																					: orderLink
																						? linkFormat( lineItem.description, orderLink, orderLink )
																						: !industrialLayout && showDescription
																							? lineItem.description
																							: undefined}
																			</Box>
																		)}
																	</Stack>
																</TableCell>
															</TableRow>
														)}
													</Fragment>
												)}
												<TableRow>
													<TableCell sx={{ p: 0 }} colSpan={6}>
														<Divider/>
													</TableCell>
												</TableRow>
											</Fragment>
										);
									} )}
							</Fragment>
						) )}
					</TableBody>
					{/* Price Totals */}
					{!pickup && Boolean( invoice.lineItems.length ) && (
						<TableFooter
							sx={{
								'display'            : 'table-row-group',
								'color'              : 'text.primary',
								'.MuiTableCell-root ': { color: 'text.primary' },
							}}>
							<TableRow key='sub'>
								{!expandOrMobile && <TableCell colSpan={industrialLayout ? 2 : 1} sx={{ border: 0 }}/>}
								<TableCell
									colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}
									sx={{ py: !expandedLines ? 0 : 'auto' }}>
									{isPdf ? 'Subtotal' : t( 'common:subtotal' )}
								</TableCell>
								<TableCell align='right' sx={{ py: !expandedLines ? 0 : 'auto' }}>
									{currencyFormat( subTotal )}
								</TableCell>
							</TableRow>
							{subTotal > 0 && !isEmpty( multiTaxesData ) && multiTaxesData.map( ( taxData,
									index ) => (
										<TableRow key={index}>
											{!expandOrMobile && <TableCell colSpan={industrialLayout ? 2 : 1}/>}
											<TableCell
											sx={{ py: !expandedLines ? 0 : 'auto' }}
											colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
												{taxData[ 0 ]}
												<Typography component='span' color='text.secondary'>
													{taxData[ 3 ] && ` (${taxData[ 1 ]}%)`}
												</Typography>
											</TableCell>
											<TableCell align='right' sx={{ py: !expandedLines ? 0 : 'auto' }}>
												{currencyFormat( taxData[ 2 ] )}
											</TableCell>
										</TableRow>
								),
							) || []}
							{!isEmpty( nonTaxPercentLineItemsTaxesData ) ? nonTaxPercentLineItemsTaxesData.map( ( taxData,
								index ) => (
									<TableRow key={index}>
										{!expandOrMobile
										&& <TableCell colSpan={industrialLayout ? 2 : 1} sx={{ border: 0 }}/>}
										<TableCell colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
											{isPdf ? 'Tax' : t( 'common:tax' )}
											<Typography component='span' color='text.secondary'>
												{` (${taxData[ 0 ]}%)`}
											</Typography>
										</TableCell>
										<TableCell align='right'>
											{currencyFormat( taxData[ 1 ] )}
										</TableCell>
									</TableRow>
							) ) : undefined}
							{orderTaxCal <= 0 || invoice.taxPercent <= 0 || taxTotal <= 0 && isEmpty( orderDiscounts )
								? undefined
								: (
									<TableRow key='taxT'>
										{!expandOrMobile
											&& <TableCell colSpan={industrialLayout ? 2 : 1} sx={{ border: 0 }}/>}
										<TableCell
											sx={{ py: 0 }}
											colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
											{isPdf ? 'Tax' : t( 'common:tax' )}
											<Typography component='span' color='text.secondary'>
												{` (${invoice.taxPercent}%)`}
											</Typography>
										</TableCell>
										<TableCell align='right'>
											{currencyFormat( orderTaxCal )}
										</TableCell>
									</TableRow>
								)}
							{!isEmpty( discounts ) && discounts.map( ( fee, index ) => (
								<TableRow key={index}>
									{!expandOrMobile && <TableCell colSpan={industrialLayout ? 2 : 1}/>}
									<TableCell
										sx={{ py: 0 }}
										colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
										{fee.name}
										<Typography component='span' color='text.secondary'>
											{fee.isPercent && ` (${fee.value}%)`}
										</Typography>
									</TableCell>
									<TableCell align='right'>
										{fee.isPercent
											? currencyFormat( subTotal * fee.value / 100 )
											: currencyFormat( fee.value )}
									</TableCell>
								</TableRow>
							) ) || []}
							{!isEmpty( fees ) && fees.map( ( fee, index ) => (
								<TableRow key={index}>
									{!expandOrMobile && <TableCell colSpan={industrialLayout ? 2 : 1}/>}
									<TableCell
										sx={{ py: 0 }}
										colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
										{fee.name}
										<Tooltip
											title={fee.name === 'Card Processing Fee'
												? 'Applied on card payments only'
												: ''}>
											<Typography component='span' color='text.secondary'>
												{fee.isPercent && ` (${fee.name === 'Card Processing Fee'
													? companyCardFeeValue || fee.value
													: fee.value}%)`}
											</Typography>
										</Tooltip>
									</TableCell>
									<TableCell align='right'>
										{fee.isPercent
											? currencyFormat( subTotalAfterDiscount * fee.value / 100 )
											: currencyFormat( fee.value )}
									</TableCell>
								</TableRow>
							) ) || []}
							<TableRow key='grand'>
								{!expandOrMobile && <TableCell colSpan={industrialLayout ? 2 : 1} sx={{ border: 0 }}/>}
								<TableCell
									colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}
									sx={{ fontWeight: 'bold', bgcolor: layoutColor, border: 0, py: 1 + ' !important' }}>
									{isPdf ? 'Total' : t( 'common:total' )}
								</TableCell>
								<TableCell
									align='right'
									sx={{ fontWeight: 'bold', bgcolor: layoutColor }}>
									{grandTotal > 0 ? currencyFormat( grandTotal ) : '$0.00'}
								</TableCell>
							</TableRow>
							{invoice.type === 'INVOICE' && invoice.metadata?.hideClientLink && invoice.client && invoicesBalance > 0 && (
								<TableRow>
									{!expandOrMobile
										&& <TableCell colSpan={industrialLayout ? 2 : 1} sx={{ border: 0 }}/>}
									<TableCell colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
										<Typography color='text.secondary'>
											{isPdf ? 'Balance due' : t( 'commerce:balance-due' )}
										</Typography>
									</TableCell>
									<Tooltip
										title={isPdf
											? 'The total of all invoices'
											: t( 'commerce:the-total-of-all-invoices' )}>
										<TableCell align='right'>
											<Typography>
												{currencyFormat( invoice.status === 'DRAFT'
													? invoicesBalance + invoice.grandTotal - pricing.paidTotal || 0
													: invoicesBalance - pricing.paidTotal || 0 )}
											</Typography>
										</TableCell>
									</Tooltip>
								</TableRow>
							)}
							{pricing?.paidTotal > 0 && grandTotal > pricing?.paidTotal && (
								<TableRow>
									{!expandOrMobile
										&& <TableCell colSpan={industrialLayout ? 2 : 1} sx={{ border: 0 }}/>}
									<TableCell colSpan={!expandOrMobile ? industrialLayout ? 3 : 4 : undefined}>
										<Typography color='text.secondary'>
											{isPdf ? 'Remaining' : `${t( 'common:remaining' )}:`}
										</Typography>
									</TableCell>
									<TableCell align='right'>
										<Typography>
											{currencyFormat( grandTotal - pricing.paidTotal || 0 )}
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					)}
				</Table>
			</TableContainer>
		</PageSection>
	);
}
