import { Order } from '@/types/schema';
import { isEmpty } from 'lodash-es';

export function invoiceSelection( invoice: Order, selections: string[] ) {
	const company = invoice.company;
	const client = invoice.client;
	return {
		
		companyLocation: !isEmpty( invoice.companyLocation ) ? {
			...invoice.companyLocation,
			address: !isEmpty( invoice.companyLocation?.address ) ? {
				...invoice.companyLocation.address,
				line1     : !selections.includes( 'company address line1' ) ? invoice.companyLocation.address.line1 : null,
				city      : !selections.includes( 'company area' ) ? invoice.companyLocation.address.city : null,
				state     : !selections.includes( 'company area' ) ? invoice.companyLocation.address.state : null,
				country   : !selections.includes( 'company area' ) ? invoice.companyLocation.address.country : null,
				postalCode: !selections.includes( 'company area' ) ? invoice.companyLocation.address.postalCode : null,
			} : null,
		} : null,
		serviceDate    : !selections.includes( 'company service' ) ? invoice.serviceDate : null,
		clientAddress  : !selections.includes( 'client address' ) ? invoice.clientAddress : null,
		company        : {
			...company,
			logo   : !selections.includes( 'company logo' ) ? company.logo : '',
			name   : !selections.includes( 'company name' ) ? company?.name : '',
			phone  : !selections.includes( 'company phone' ) ? company.phone : '',
			website: !selections.includes( 'company website' ) ? company?.website : '',
			email  : !selections.includes( 'company email' ) ? company?.email : '',
		},
		client         : client ? {
			...client,
			email: !selections.includes( 'client email' ) ? client.email : '',
			phone: !selections.includes( 'client phone' ) ? client.phone : '',
			cell : !selections.includes( 'client cell' ) ? client.cell : '',
		} : null,
		
	};
}

export function invoiceLineItemsSelection( selections: string[] ) {
	const showUnit = !selections.includes( 'item unit' );
	const showName = !selections.includes( 'item name' );
	const showPrice = !selections.includes( 'item price' );
	const showQuantity = !selections.includes( 'item quantity' );
	const showCode = !selections.includes( 'item code' );
	const showSku = !selections.includes( 'item sku' );
	const showDescription = !selections.includes( 'item description' );
	const showCategory = !selections.includes( 'item category' );
	const expandedLines = !selections.includes( 'expand lines' );
	const showTaxedLineItems = selections.includes( 'taxed item' );
	const hideImage = selections.includes( 'item image' );
	const showTotal = !selections.includes( 'item total' );
	
	return {
		showName,
		showUnit,
		hideImage,
		showPrice,
		showQuantity,
		showCode,
		showDescription,
		showCategory,
		expandedLines,
		showSku,
		showTotal,
		showTaxedLineItems,
	};
}

export function trimServiceDate( invoice: Order, selections: string[] ) {
	if ( isEmpty( selections ) || !invoice.serviceDate ) return { hideDateTime: false, hideServiceDate: false };
	const hideDateTime = selections.includes( 'service time' );
	const hideServiceDate = selections.includes( 'Service date & time' );
	return { hideDateTime, hideServiceDate };
	
}
