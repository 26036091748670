import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import currencyFormat from '@/helpers/currencyFormat';
import { PurchasesSelect } from '@/pages/formSelects/selectsGQL';
import { Purchase, QueryPurchasesReadArgs } from '@/types/schema';
import { Divider, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function PurchaseSelect( props: Omit<FormGraphQLAutoCompleteProps<Purchase, QueryPurchasesReadArgs>, 'query' | 'queryKey'> ) {
	const { t } = useTranslation();
	
	return (
		<FormGraphQLAutocomplete<Purchase>
			label='Purchase'
			queryKey={[ 'purchase' ]}
			query={PurchasesSelect}
			getOptionLabel={( {
				number,
				metadata,
				grandTotal,
			}: Purchase ) => `#${metadata?.customNumber || number} - (${currencyFormat( grandTotal )})`}
			renderOption={( props, option ) => (
				<Fragment key={option.id ?? ''}>
					<MenuItem {...props}>
						<Stack spacing={.3} width='100%'>
							<ListItemText
								sx={{ width: '100%' }}
								primary={`${option.menu?.vendorName || option.menu?.vendorContact || 'No vendor'}`}
								secondary={(
									<Stack direction='row' sx={{ justifyContent: 'space-between' }}>
										<Typography>
											{`#${option.metadata?.customNumber || option.number}`}
										</Typography>
										<Typography>
											{currencyFormat( option.grandTotal )}
										</Typography>
									</Stack>
								)}
							/>
						</Stack>
					</MenuItem>
					<Divider sx={{ my: 1 }}/>
				</Fragment>
			)}
			{...props}
			textFieldProps={{ label: props.label || t( 'common:purchase' ), ...props.textFieldProps }}
		/>
	);
}
